import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { useParams, Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { travelApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";
import { toast, ToastContainer } from "react-toastify";
import ProductDialog from "components/ProductDialog/ProductDialog";

const useStyles = makeStyles(styles);

const DeleteAgentMerchantProductPricing = props => {
  const classes = useStyles();

  const [input, setInput] = useState({
    productID: null,
    agentID: null,
    startDate: null,
    untilDate: null,
    ageVariant: "",
    price: 0,
    ageRangeFrom: "",
    ageRangeUntil: ""
  });
  const [disabled, setDisabled] = React.useState(false);
  const [redirect, setRedirect] = useState(false);

  const [productData, setProductData] = React.useState({
    id: "",
    name: ""
  });

  const [agentData, setAgentData] = React.useState({
    id: "",
    name: "",
    billingCurrency: null
  });
  const [showProductModal, setShowProductModal] = React.useState(false);

  const deleteAgentMerchantProductPricingSignal = useRef(new AbortController());

  const { id } = useParams();

  useEffect(() => {
    travelApi
      .get("agentpricings/" + id, {
        signal: deleteAgentMerchantProductPricingSignal.current.signal
      })
      .then(resp => {
        setProductData({
          id: resp.product.id,
          name: resp.product.name
        });
        setAgentData({
          billingCurrency: resp.agent.billingCurrency,
          id: resp.agent.id,
          name: resp.agent.name
        });
        setInput({
          ...input,
          agentID: resp.agent.id,
          ageRangeFrom: resp.ageRangeFrom,
          ageRangeUntil: resp.ageRangeUntil,
          ageVariant: resp.ageVariant,
          price: resp.price,
          productID: resp.product.id,
          startDate: new Date(resp.startDate),
          untilDate: new Date(resp.untilDate)
        });
      });

    return () => deleteAgentMerchantProductPricingSignal.current.abort();
  }, []);

  const handleStartDateChanged = e => {
    if (e !== "") {
      const dateTemp = new Date(e);
      dateTemp.setHours(7);
      setInput({
        ...input,
        startDate: dateTemp
      });
    } else {
      setInput({
        ...input,
        startDate: null
      });
    }
  };

  const handleUntilDateChanged = e => {
    if (e !== "") {
      const dateTemp = new Date(e);
      dateTemp.setHours(7);
      setInput({
        ...input,
        untilDate: dateTemp
      });
    } else {
      setInput({
        ...input,
        untilDate: null
      });
    }
  };

  const handleProductIDChanged = e => {
    if (e) {
      setProductData({ id: e.id, name: e.name });
      setShowProductModal(false);
    }
  };

  const handlePriceChanged = e =>
    setInput({
      ...input,
      price:
        e.target.value === "" ? null : e.target.value.replace(/[^0-9]/g, "")
    });

  const handleAgeVariantChanged = e =>
    setInput({
      ...input,
      ageVariant: e.target.value === "" ? null : e.target.value
    });

  const handleAgeRangeFromChanged = e =>
    setInput({
      ...input,
      ageRangeFrom: e.target.value === "" ? null : e.target.value
    });

  const handleAgeRangeUntilChanged = e =>
    setInput({
      ...input,
      ageRangeUntil: e.target.value === "" ? null : e.target.value
    });

  const DeleteAgentMerchantProductPricing = () => {
    setDisabled(true);

    const resolveFromTravelApi = new Promise((resolve, reject) => {
      travelApi
        .delete("agentpricings/" + id, {
          signal: deleteAgentMerchantProductPricingSignal.current.signal
        })
        .then(() => {
          resolve({ isSuccess: true });
        })
        .catch(error => {
          reject({ isSuccess: true, error });
        });
    });

    toast
      .promise(resolveFromTravelApi, {
        pending: "...Deleting data",
        success: {
          render({ data }) {
            if (data.isSuccess) {
              return `Delete data success`;
            } else {
              return `Error : ${JSON.stringify(
                data.error?.response.data.title
              )}`;
            }
          }
        },
        error: {
          render({ data }) {
            return `Error : ${JSON.stringify(data.error?.response.data.title)}`;
          }
        }
      })
      .then(resp => {
        if (resp && resp.isSuccess) {
          setRedirect(true);
        }
      })
      .finally(() => {
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + input.agentID} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              DELETE MERCHANT PRODUCT PRICING
            </h4>
          </CardHeader>
          <CardBody>
            <i>*Are you sure to delete this merchant product pricing?</i>
            <br />
            <Button onClick={() => props.history.goBack()} value="Back">
              Cancel
            </Button>
            <Button
              color="rose"
              disabled={disabled}
              onClick={() => DeleteAgentMerchantProductPricing()}
              value="Cancel"
            >
              Delete
            </Button>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAgent"
                      className={classes.selectLabel}
                    >
                      Merchant Product
                    </InputLabel>
                    <Input
                      disabled
                      onClick={() => setShowProductModal(prev => !prev)}
                      className={classes.select}
                      value={productData ? productData.name : ""}
                    />
                    <ProductDialog
                      openModal={showProductModal}
                      setOpenModal={val => setShowProductModal(val)}
                      onSelect={handleProductIDChanged}
                      agentID={agentData.id}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText={
                      "Price " +
                      `${
                        agentData.billingCurrency
                          ? "(" + agentData.billingCurrency.code + ")"
                          : ``
                      }`
                    }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handlePriceChanged,
                      value: input.price === null ? "" : input.price,
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.startDate}
                      onChange={handleStartDateChanged}
                      closeOnSelect={true}
                      timeFormat={false}
                      inputProps={{
                        placeholder: "Start Date",
                        disabled: true
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.untilDate}
                      onChange={handleUntilDateChanged}
                      closeOnSelect={true}
                      timeFormat={false}
                      inputProps={{
                        placeholder: "Until Date",
                        disabled: true
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <CustomInput
                    labelText="Age Variant"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleAgeVariantChanged,
                      value: input.ageVariant,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={6}>
                  <CustomInput
                    labelText="Age Range From"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleAgeRangeFromChanged,
                      value: input.ageRangeFrom,
                      disabled: true,
                      type: "number"
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <CustomInput
                    labelText="Age Range Until"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleAgeRangeUntilChanged,
                      value: input.ageRangeUntil,
                      disabled: true,
                      type: "number"
                    }}
                  />
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
      <ToastContainer theme="light" />
    </GridContainer>
  );
};

export default DeleteAgentMerchantProductPricing;

DeleteAgentMerchantProductPricing.propTypes = {
  history: PropTypes.object,
  id: PropTypes.string
};
