import React, { useEffect, useRef } from "react";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { voucherApi, orderApi, creditMonitoringApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function AgentCreateVoucherType(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { agentID } = useParams();
  const [agentCredit, setAgentCredit] = React.useState(null);
  const [voucherTypeList, setVoucherTypeList] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    orderApi
      .get(`/VoucherTypes`, {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null,
            sort: 0
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setVoucherTypeList(data.records))
      .catch(error => console.log(error));
    creditMonitoringApi
      .get("/Agents/" + agentID, {
        signal: source.current.signal
      })
      .then(data => setAgentCredit(data))
      .catch(error => console.log(error));
  }, [agentID]);

  const [state, setState] = React.useState({
    voucherTypeID: null,
    billingPrice: 0,
    recommendedRetailPrice: 0,
    effectiveDate: null,
    expiryDate: null,
    trips: []
  });

  const handleVoucherTypeChanged = (e, v) => {
    if (v === null) {
      setState({
        ...state,
        voucherTypeID: null,
        trips: []
      });
      return;
    }
    if (state.voucherTypeID !== v.id)
      voucherApi
        .get("/VoucherTypes/" + v.id, {
          signal: source.current.signal
        })
        .then(data => {
          setState({
            ...state,
            voucherTypeID: v.id,
            trips: data.trips.map(trip => {
              return {
                description: trip.description,
                voucherTypeTripID: trip.id,
                pricing: {
                  ticket: 0,
                  pdf: 0,
                  terminalFee: 0,
                  surcharge: 0,
                  confirmationFee: 0,
                  vtlFee: 0
                }
              };
            })
          });
        })
        .catch(error => console.log(error));
  };

  const handleBillingPriceChanged = e => {
    let newBillingPrice = parseFloat(e.target.value);
    if (newBillingPrice !== state.billingPrice)
      setState({ ...state, billingPrice: newBillingPrice });
  };

  const handleRecommendedRetailPriceChanged = e => {
    let newRecommendedRetailPrice = parseFloat(e.target.value);
    if (newRecommendedRetailPrice !== state.recommendedRetailPrice)
      setState({ ...state, recommendedRetailPrice: newRecommendedRetailPrice });
  };

  const handleEffectiveDateChanged = e =>
    setState({
      ...state,
      effectiveDate: e === "" ? null : e
    });

  const handleExpiryDateChanged = e =>
    setState({
      ...state,
      expiryDate: e === "" ? null : e
    });

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    orderApi
      .post(
        "/Agents/" + agentID + "/VoucherTypes",
        {
          ...state,
          effectiveDate: state.effectiveDate,
          expiryDate: state.expiryDate !== null ? state.expiryDate : null
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + agentID} />
  ) : (
    agentCredit !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ADD VOUCHER TYPE</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <Autocomplete
                  id="voucherType"
                  onChange={handleVoucherTypeChanged}
                  options={voucherTypeList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  renderOption={option => (
                    <React.Fragment>
                      <b>{option.code}</b> - {option.name}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Voucher Type"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Billing Currency"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          agentCredit.billingCurrency === null
                            ? ""
                            : agentCredit.billingCurrency.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Billing Price"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleBillingPriceChanged,
                        value: state.billingPrice
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Recommended Retail Price"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleRecommendedRetailPriceChanged,
                        value: state.recommendedRetailPrice
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <Datetime
                        value={state.effectiveDate}
                        onChange={handleEffectiveDateChanged}
                        timeFormat={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "Effective Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <Datetime
                        value={state.expiryDate}
                        onChange={handleExpiryDateChanged}
                        timeFormat={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "Expiry Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {state.trips.map(record => {
                  return (
                    <GridContainer key={record.voucherTypeTripID}>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Trip"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: record.description
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Ticket"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.ticket = e.target.value;
                              else
                                newTrips[index].pricing.ticket = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.ticket
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="PDF"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.pdf = e.target.value;
                              else
                                newTrips[index].pricing.pdf = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.pdf
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Terminal Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.terminalFee =
                                  e.target.value;
                              else
                                newTrips[
                                  index
                                ].pricing.terminalFee = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.terminalFee
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Surcharge"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.surcharge =
                                  e.target.value;
                              else
                                newTrips[index].pricing.surcharge = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.surcharge
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Confirmation Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.confirmationFee =
                                  e.target.value;
                              else
                                newTrips[
                                  index
                                ].pricing.confirmationFee = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.confirmationFee
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="VTL Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.vtlFee = e.target.value;
                              else
                                newTrips[index].pricing.vtlFee = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.vtlFee
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText={<b>Total</b>}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            disabled: true,
                            value:
                              record.pricing.ticket +
                              record.pricing.pdf +
                              record.pricing.terminalFee +
                              record.pricing.surcharge +
                              record.pricing.confirmationFee +
                              record.pricing.vtlFee
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  );
                })}
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

AgentCreateVoucherType.propTypes = {
  history: PropTypes.object
};
