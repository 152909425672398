import LoyaltyIcon from "@material-ui/icons/Loyalty";
import CreateMerchantPromo from "views/MerchantPromo/CreateMerchantPromo";
import EditMerchantPromo from "views/MerchantPromo/EditMerchantPromo";
import MerchantPromo from "views/MerchantPromo/MerchantPromo";
import MerchantPromoDetail from "views/MerchantPromo/MerchantPromoDetail";

const merchantPromo = () => {
  let views = [];

  views.push({
    path: "/MerchantPromo/List",
    name: "M Promo",
    icon: LoyaltyIcon,
    component: MerchantPromo,
    layout: "/admin"
  });

  return views;
};

const merchantPromoHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/MerchantPromo/Create",
      name: "Create Merchant Promo",
      component: CreateMerchantPromo,
      layout: "/admin"
    },
    {
      path: "/MerchantPromo/Detail",
      name: "Merchant Promo Detail",
      component: MerchantPromoDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantPromo/Edit",
      name: "Merchant Promo Edit",
      component: EditMerchantPromo,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Merchant Promo Hidden",
        state: "merchantPromoHidden",
        hideInSidebar: true,
        views: views
      }
    ];

  return [];
};

export { merchantPromo, merchantPromoHidden };
